<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="p-4 text-gray-100">
              <div>
                <a
                  v-on:click="$router.go(-1)"
                  class="flex items-center space-x-2 text-center cursor-pointer"
                  ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
                >
              </div>
            </div>
            <div class="mt-5">
              <div>
                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-40 md:h-80 lg:h-96 w-full"
                  :src="img_cover"
                  alt=""
                  v-if="img_cover"
                />

                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-40 md:h-80 lg:h-96 w-full"
                  src="https://promptdue.com/img/home.png"
                  alt=""
                  v-else
                />
              </div>

              <div class="ml-5 -mt-10">
                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-20 w-20 sm:w-32 sm:h-32 border-2 rounded-full border-red-500"
                  :src="img_profile"
                  alt=""
                  v-if="img_profile"
                />

                <img
                  class="inset-0 object-cover ease-in-out opacity-100 h-20 w-20 sm:w-32 sm:h-32 border-2 rounded-full border-red-500"
                  src="https://media.discordapp.net/attachments/1080058777971212370/1083317410674769940/PromptDue_Logo_64x64px_02.png?width=128&height=128"
                  alt=""
                  v-else
                />
              </div>

              <div class="">
                <div>
                  <div class="mt-3 flex">
                    <h3
                      class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-100 to-blue-100"
                      v-if="getUser.name != '' && getUser.name"
                    >
                      @{{ getUser.name }}
                    </h3>
                    <h3
                      class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-100 to-blue-100"
                      v-else
                    >
                      @ {{ getUser.address }}
                    </h3>
                  </div>

                  <hr
                    class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 mt-4"
                  />

                  <h3
                    class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-4"
                  >
                    {{ getUser.description }}
                  </h3>

                  <h3 class="text-lg font-bold text-white mt-10">Social</h3>

                  <div class="text-gray-200 mt-2 flex">
                    <a
                      :href="facebook"
                      v-if="facebook && facebook != ''"
                      class="text-3xl font-bold"
                    >
                      <i class="fab fa-facebook-square"></i>
                    </a>
                    <a
                      :href="telegram"
                      v-if="telegram && telegram != ''"
                      class="text-3xl font-bold ml-3"
                    >
                      <i class="fab fa-telegram"></i>
                    </a>
                    <a
                      :href="instagram"
                      v-if="instagram && instagram != ''"
                      class="text-3xl font-bold ml-3"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 mt-10" />
          </div>
        </div>
      </div>

      <div class="z-0">
        <div
          class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
        >
          <h3
            class="sm:mt-1 mt-7 text-xl font-bold xl:text-xl text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
          >
            Search Prompts or Images
          </h3>

          <form
            v-on:submit.prevent="searchPromptByKeywords"
            class="flex justify-center border-2 py-1 sm:py-3 px-2 rounded-lg max-w-xl mt-2 bg-gray-600"
          >
            <input
              placeholder="Search AI images"
              v-model="searchPrompt"
              class="flex-grow outline-none text-sm bg-gray-600 text-gray-100"
            />

            <button>
              <img
                class="w-7 cursor-pointer"
                src="@/assets/gif/zoom.gif"
                alt=""
              />
            </button>
          </form>

          <h3
            class="mt-2 text-xs font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
            v-if="addressSearch"
          >
            Search:
            <b class="text-blue-300">
              {{ addressSearch }} ...

              <a href="/search" class="text-red-500"> X </a>
            </b>
          </h3>

          <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />

          <div class="grid grid-cols-2 gap-2 lg:grid-cols-4 md:grid-cols-3">
            <div
              class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 5px"
              v-for="s in prompts"
              :key="s"
            >
              <div
                class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
              >
                <div class="text-sm border-none rounded bg-gray-900">
                  <a class="" :href="linkDetail(s)">
                    <img
                      :src="s.img_1"
                      alt="Image 1"
                      class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-52 lg:h-72 w-full"
                    />

                    <img
                      :src="s.img_2"
                      alt="Image 2"
                      class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-52 lg:h-72 w-full pl-0.5 pr-0.5 mt-0.5"
                    />

                    <!-- <img :src="s.img_1" /> -->

                    <div class="flex -mt-7 ml-2">
                      <div class="flex-none">
                        <div
                          class="flex border rounded bg-blue-900 bg-opacity-75"
                        >
                          <div class="text-xs p-1 text-gray-200">
                            <i class="fa fa-check-square text-gray-200"></i>
                            {{ s.prompt_type }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="flex-grow text-right text-gray-200 mr-2 hidden lg:block"
                      >
                        <h3 class="text-sm font-bold">
                          <i class="fas fa-eye"></i>

                          {{ s.view }}

                          <i class="fas fa-heart ml-2"></i>

                          {{ s.favorite }}
                        </h3>
                      </div>
                    </div>

                    <div class="flex"></div>

                    <div class="flex bg-black pt-1">
                      <div class="flex-none">
                        <h4 class="text-xs p-2 mt-1 text-gray-200">
                          ⛵ {{ s.title.slice(0, 12) }} ...
                        </h4>
                      </div>

                      <div class="flex-grow p-2 text-right text-gray-200">
                        {{ s.price }}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col items-center mt-8">
            <a
              href="/search/Free"
              class="text-xl font-bold xl:text-xl text-transparent text-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer text-shadow-white"
            >
              More Prompts <i class="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <StickFooter />
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import Prompt from "@/api/prompt";
import User from "@/api/user";

export default {
  data() {
    return {
      prompts: [],
      address: this.$route.params.address || null,
      img_3: "",
      img_profile: "",
      img_cover: "",
      name: "name",
      description: "",
      facebook: "",
      telegram: "",
      instagram: "",
      getUser: {},
      searchPrompt: "",
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },

  methods: {
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
    async searchPromptByKeywords() {
      if (this.searchPrompt != "" && this.searchPrompt != null) {
        this.getFeaturedPrompts(this.searchPrompt);
      } else {
        this.getFeaturedPrompts();
      }
    },
    async getFeaturedPrompts(textSearch = null) {
      await Prompt.filter({
        type: "News",
        brand: "all",
        category: "all",
        address: this.address,
      })
        .then((res) => {
          if (textSearch) {
            this.prompts = res.data.prompts.filter((p) => {
              if (p.prompt_type == null) {
                p.prompt_type = "promptdue";
              }
              if (p.title == null) {
                p.title = "promptdue";
              }
              if (p.description == null) {
                p.description = "promptdue";
              }
              if (p.prompt == null) {
                p.prompt = "promptdue";
              }
              if (p.prompt_instructions == null) {
                p.prompt_instructions = "promptdue";
              }

              return (
                p.prompt_type.toLowerCase().search(textSearch.toLowerCase()) !=
                  -1 ||
                p.title.toLowerCase().search(textSearch.toLowerCase()) != -1 ||
                p.description.toLowerCase().search(textSearch.toLowerCase()) !=
                  -1 ||
                p.prompt.toLowerCase().search(textSearch.toLowerCase()) != -1 ||
                p.prompt_instructions
                  .toLowerCase()
                  .search(textSearch.toLowerCase()) != -1
              );
            });
          } else {
            this.prompts = res.data.prompts;
          }

          if (res.data.prompts.length > 0) {
            this.img_3 = res.data.prompts[0].img_3;
          }
          if (res.data.prompts.length > 1) {
            this.img_3 = res.data.prompts[1].img_3;
          }

          if (res.data.prompts.length > 2) {
            this.img_3 = res.data.prompts[2].img_3;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getData() {
      await User.data(this.address)
        .then((res) => {
          this.getUser = res.data;

          if (this.getUser.img_profile) {
            this.img_profile = this.getUser.img_profile;
          }
          if (this.getUser.img_cover) {
            this.img_cover = this.getUser.img_cover;
          }

          if (this.getUser.name) {
            this.name = this.getUser.name;
          } else {
            this.name = this.getUser.address;
          }

          if (this.getUser.description) {
            this.description = this.getUser.description;
          }
          if (this.getUser.facebook) {
            this.facebook = this.getUser.facebook;
          }
          if (this.getUser.telegram) {
            this.telegram = this.getUser.telegram;
          }

          if (this.getUser.instagram) {
            this.instagram = this.getUser.instagram;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getFeaturedPrompts();

    this.getData();
  },
};
</script>
